.b-card {
	border-bottom: $global-line solid $color-divider;
	display: flex;
	flex-direction: column;
	margin-bottom: 35px;
	overflow: hidden;
	padding: 0 0 30px 10px;
	position: relative;

	@include media(lg-up) {
		padding-left: 20px;
	}

	.b-history_page & {
		border: $global-line solid $color-divider;
		border-radius: $global-radius;
		box-shadow: $depth-1;
		margin-bottom: 20px;
		padding: 15px;

		@include media(lg-up) {
			padding: 20px;
		}
	}

	&-title {
		font-size: 17px;
		font-weight: 600;
		line-height: 1.25;
		margin-bottom: 20px;
	}

	&-body {
		font-size: 15px;
		margin-bottom: 20px;

		.b-address {
			margin-bottom: 5px;
		}
	}

	&-button {
		@include g-link;

		color: $color-title;
		font-size: 15px;
		font-weight: 600;
		margin-right: 20px;
		text-transform: capitalize;

		&.m-no_capitalize {
			text-transform: none;
		}

		.b-history_page & {
			display: block;
			margin-right: 0;
			text-transform: none;

			@include media(lg-up) {
				text-align: right;
			}
		}

		.b-history_page & + & {
			margin-top: 15px;
		}
	}

	&-list {
		line-height: 1.7;
	}

	&-list_link {
		@include g-link;

		transition: color $motion-fast;
	}

	&-message {
		margin-bottom: 20px;
	}

	&-thumbnails {
		display: flex;
		margin-top: 20px;
	}

	&-thumbnails_item {
		margin-right: 10px;
		max-width: 100%;
		width: 76px;

		&:last-child {
			margin-right: 0;
		}

		&.m-additional {
			align-items: center;
			background-color: $color-bg-account-thumbnail;
			border: $global-line solid $color-bg-account-thumbnail;
			color: $color-title;
			display: flex;
			font-size: 15px;
			font-weight: 600;
			justify-content: center;
			text-decoration: underline;
			transition: $motion-ease;
			transition-property: background-color;

			@include hover-supported {
				&:hover {
					background-color: $color-bg;
					text-decoration: none;
				}
			}
		}

		&.m-mobile {
			@include media(lg-up) {
				display: none;
			}
		}

		&.m-desktop {
			@include media(md-down) {
				display: none;
			}
		}
	}

	&-thumbnails.m-wishlist &-thumbnails_item {
		width: 101px;

		@include media(lg-up) {
			width: 110px;
		}
	}

	&-thumbnails_picture {
		@include g-image_container(_container, aspect-ratio(1, 1));

		img {
			@include g-image_container(_img);
		}
	}

	&-subscription_text {
		color: $color-text-disabled;
	}

	&-footer {
		.b-history_page & {
			@include media(lg-up) {
				font-size: 13px;
				margin: 0;
				position: absolute;
				right: 20px;
				top: 20px;
			}
		}
	}
}
