.b-account_activation {
	font-size: 13px;
	margin: 0 auto;
	max-width: 288px;
	padding: 45px 0;
	text-align: center;

	&-error {
		display: inline-block;
		margin-bottom: 20px;
	}

	&-title {
		@include g-title;
	}

	&-label {
		margin-bottom: 20px;
	}
}
