.b-summary_payment {
	&-item {
		align-items: center;
		display: flex;

		& + & {
			margin-top: 8px;
		}
	}

	&-number {
		margin-left: 8px;
	}
}
