.b-account_nav {
	margin-bottom: 30px;

	@include media(lg-up) {
		margin-bottom: 40px;
	}

	&-item {
		border-bottom: $global-line solid $color-divider;
		cursor: pointer;
		font-size: 15px;
		padding: 0;
		transition: color $motion-fast;

		&.m-active {
			font-weight: 600;
		}
	}

	&-item_link {
		color: $color-primary;
		display: block;
		height: 100%;
		padding: 16px 10px;

		@include hover-supported {
			&:hover {
				color: $color-action;
			}
		}
	}
}
