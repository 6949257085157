.b-downloads {
	border-bottom: $global-line solid $color-divider;
	margin-bottom: 35px;
	padding: 0 0 30px;

	&-title {
		@include g-heading_4;

		color: $color-title;
		margin-bottom: 25px;

		@include media(md-down) {
			text-align: center;
		}
	}
}
