.b-history_page {
	&-top {
		color: $color-primary;
		margin-bottom: 16px;

		@include media(md-down) {
			text-align: center;
		}

		@include media(lg-up) {
			display: flex;
			justify-content: space-between;
		}
	}

	&-title {
		@include g-heading_4;

		color: $color-title;

		@include media(md-down) {
			margin-bottom: 10px;
		}
	}

	&-track_order {
		@include g-link;

		font-weight: 600;
		transition: color $motion-fast;
	}

	&-start_shopping {
		margin: 20px 0 45px;
	}

	&-find_order {
		font-size: 15px;
	}

	&-find_order_link {
		@include g-link;

		color: $color-title;
		font-weight: 600;
		transition: color $motion-fast;
	}

	&-filter {
		align-items: center;
		color: $color-primary;
		display: flex;
		font-size: 13px;
		justify-content: space-between;
		line-height: 1;
		margin-bottom: 20px;

		@include media(md-up) {
			align-items: flex-end;
		}
	}

	&-select_wrap {
		align-items: center;
		display: flex;
	}

	&-select_label {
		margin-right: 15px;
	}

	&-select {
		width: 157px;
	}
}
