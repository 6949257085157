.b-account_delete {
	display: block;
	text-align: center;

	&-title {
		color: $color-title;
		font-size: 24px;
		margin-bottom: 10px;
	}

	&-form {
		background-color: $color-natural;
		border-radius: 10px;
		margin-top: 20px;
		padding: 12px 15px;
	}

	&-action {
		margin-top: 10px;
	}

	&-submit {
		padding: 0 20px;
	}

	p {
		margin-bottom: 20px;
	}

	.b-checkbox {
		text-align: left;
	}
}
