.b-track_notification {
	margin: 0 auto 20px;
	text-align: center;

	@include media(lg-up) {
		max-width: 785px;
	}

	&-title {
		color: $color-title;
		font-size: 17px;
		font-weight: 600;
		line-height: 21px;
		margin-bottom: 5px;
	}

	&-image {
		color: $color-action-obj;
		margin-bottom: 7px;
	}

	&-message {
		border-bottom: 1px solid $color-divider;
		color: $color-primary;
		padding-bottom: 20px;
	}

	&-link {
		@include g-link;

		font-size: 13px;
		font-weight: 600;
		line-height: 18px;
		transition: color $motion-fast;
	}
}
