.b-order_product {
	border: $global-line solid $color-divider;
	border-radius: $global-radius;
	box-shadow: $depth-1;
	color: $color-text;
	padding: 15px;
	position: relative;

	&.m-confirmation {
		box-shadow: none;
	}

	& + & {
		margin-top: 20px;
	}

	&-top {
		color: $color-primary;
		font-size: 13px;
		margin: 20px 0;
	}

	&-inner {
		display: flex;
	}

	&-msg {
		background-color: $color-bg;
		border: $global-line solid $color-error;
		border-radius: 2px;
		color: $color-error;
		font-size: 13px;
		margin-bottom: 10px;
		padding: 4px 8px;

		@include media(lg-up) {
			margin-right: 60px;
		}
	}

	&-image {
		margin-right: 15px;
		min-width: 83px;
		width: 83px;
	}

	&-link {
		display: block;
	}

	&.m-account &-image {
		@include media(lg-up) {
			min-width: 149px;
			width: 149px;
		}
	}

	&.m-bonus &-image {
		min-width: 76px;
		width: 76px;
	}

	&-picture {
		@include g-image_container(_container, aspect-ratio(1, 1));

		border-radius: 2px;

		img {
			@include g-image_container(_img);
		}
	}

	&-information {
		width: 100%;
	}

	&.m-confirmation &-details_bottom {
		align-items: flex-end;
		display: flex;
		justify-content: space-between;
		margin-top: 15px;
	}

	&.m-account &-information {
		@include media(lg-up) {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			min-height: 149px;
		}
	}

	&.m-bonus &-information {
		@include media(lg-up) {
			min-height: 76px;
		}
	}

	&-title {
		font-size: 15px;
		font-weight: 500;
		line-height: 1.5;
		margin-bottom: 14px;
	}

	&-message {
		color: $color-action;
		font-size: 13px;
		font-weight: 600;
	}

	&.m-account &-title {
		font-size: 13px;
		font-weight: 400;
		margin-bottom: 5px;
	}

	&-price {
		font-size: 13px;
		margin-bottom: 5px;

		.b-price-item.m-old {
			color: $color-text-grey;
			font-weight: 500;
		}
	}

	&.m-confirmation &-price {
		display: none;
	}

	&-qty {
		display: flex;
		font-size: 13px;
		margin-top: 5px;
	}

	&.m-confirmation &-qty {
		margin-top: 0;
	}

	&-pdf_line {
		align-items: center;
		display: flex;
		justify-content: space-between;
		margin-top: 15px;
	}

	&-badge {
		background-color: $color-bg-cart-badge;
		border-radius: 2px;
		color: $color-bg;
		display: inline-block;
		padding: 2px 8px;
		text-align: center;
		text-transform: uppercase;
	}

	&-download {
		color: $color-title;
		font-weight: 600;
	}

	&-price_total {
		font-size: 15px;
		font-weight: 600;
		line-height: 1.25;

		@include media(md-down) {
			.b-price-subtotal {
				display: flex;
			}

			.b-price-subtotal_item.m-old {
				margin-left: 8px;
			}
		}

		@include media(lg-up) {
			position: absolute;
			right: 15px;
			top: 15px;

			&.m-mobile {
				display: none;
			}
		}
	}

	&.m-account &-price_total {
		&.m-mobile {
			display: none;
		}
	}

	&.m-confirmation &-price_total {
		@include media(md-down) {
			&.m-desktop {
				display: none;
			}
		}

		@include media(lg-up) {
			font-size: 17px;
		}
	}

	&-status {
		display: flex;
		font-size: 13px;
		justify-content: flex-end;

		@include media(lg-up) {
			align-self: flex-end;
		}

		&.m-column {
			align-items: flex-end;
			flex-direction: column;
			text-align: right;
		}
	}

	&.m-confirmation &-status {
		display: none;
	}

	&-status_label {
		font-weight: 600;
	}

	&-status.m-row &-status_label {
		margin-right: 5px;
	}

	&-voucher {
		font-size: 13px;
	}

	&-bottom {
		@include media(md-down) {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin-top: 15px;
		}
	}

	&.m-confirmation &-bottom {
		@include media(md-down) {
			margin-top: 0;
		}
	}

	&.m-confirmation.m-bonus &-bottom {
		@include media(md-down) {
			justify-content: flex-end;
			margin-top: 10px;
		}
	}

	&-bundle {
		font-size: 13px;
		margin-top: 20px;
	}

	&.m-confirmation .b-minicart_product-qty_inline {
		font-weight: 400;
	}

	.b-cart_bundle-label {
		font-size: 13px;
		text-align: left;
	}

	.b-evoucher {
		padding: 0;
	}

	&.m-account .b-evoucher {
		margin: 10px 0;
	}

	.b-price-item.m-free {
		font-size: 17px;
		font-weight: 600;
	}
}
