.b-account_benefits {
	color: $color-burgundy;
	font-size: 13px;
	margin-bottom: 20px;

	&-title {
		font-weight: 500;
		line-height: 16px;
		margin-bottom: 10px;
	}

	&-list {
		list-style: disc inside;
		margin-left: 8px;
	}

	&-item {
		&::marker {
			margin-right: 8px;
		}
	}
}
