/* stylelint-disable */
// Turned off rules for Adyen styles.
.b-payment_form {
	&-shade {
		background-color: $color-light-grey;
		border-radius: $global-radius;
		padding: 12px 24px 32px;
	}
}

.b-payment_form .adyen-checkout {
	$_selector: '.adyen-checkout';

	&__label__text {
		color: $color-primary;
		font-size: 13px;
		font-weight: 400;
		line-height: 18px;
		padding-bottom: 6px;

		&::after {
			content: '*';
		}
	}

	&__label--focused #{$_selector}__label__text {
		color: $color-action-obj;
	}

	&__input {
		border-color: $color-input-border;
		color: $color-primary !important;
		box-shadow: none;

		&--focus,
		&--focus:hover,
		&:active,
		&:focus,
		&:active:hover,
		&:focus:hover {
			border-color: $color-action-obj !important;
			box-shadow: none !important;
		}
	}

	&__checkbox__input {
		background-color: $color-bg;
		border-color: $color-border-active;
		height: 16px;
		width: 16px;
	}

	&__checkbox__input:checked + #{$_selector}__checkbox__label::after {
		background-color: $color-action-obj !important;
		border: 1px solid $color-action-obj !important;
	}

	&__checkbox__input:focus + #{$_selector}__checkbox__label::after {
		border: 1px solid $color-action-obj !important;
		box-shadow: none !important;
	}

	&__field-wrapper {
		display: block !important;
	}

	&__field {
		margin-left: 0 !important;
		margin-top: 24px;

		.b-payment_accordion-content[aria-hidden='true'] & {
			margin-top: 0;
		}
	}

	&__field--50 {
		@include media(sm) {
			width: 100%;
		}
	}

	&__input--valid {
		border-bottom-color: $color-input-border !important;
	}
}

.b-card_content.m-saved .adyen-checkout {
	$_selector: '.adyen-checkout';

	&__field {
		display: none;

		&--securityCode {
			display: block;
			margin-top: 8px;
			max-width: 200px;
			min-width: 150px;
			width: 50%;

			@include media(sm) {
				margin-top: 16px;
			}
		}
	}
}

.adyen-checkout__threeds2__challenge.adyen-checkout__threeds2__challenge--05 {
	@include media(sm) {
		min-height: 650px;
	}
}

/* stylelint-enable */
