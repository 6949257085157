.b-password_reset {
	padding: 50px 0 30px;

	&-form {
		margin: auto;
		max-width: 271px;
	}

	&-title {
		font-size: 20px;
	}

	&-text {
		color: $color-primary;
		font-size: 13px;
		line-height: 18px;
		text-align: center;
	}

	&-content {
		margin: auto;
		max-width: 500px;
		padding: 0 38px;
	}

	&.m-success {
		padding: 90px 0;
	}

	&.m-forgot_password &-form {
		max-width: 290px;
	}

	&.m-forgot_password &-title,
	&.m-success &-title {
		margin-bottom: 10px;
	}
}
