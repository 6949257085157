.b-subscriptions {
	border-bottom: $global-line solid $color-divider;
	margin-bottom: 35px;
	padding: 0 0 30px;

	&-title {
		@include g-heading_4;

		color: $color-title;
		margin-bottom: 25px;

		@include media(md-down) {
			text-align: center;
		}
	}

	&-top {
		font-size: 15px;
		margin-bottom: 20px;
	}

	&-top_title {
		font-size: 17px;
		font-weight: 600;
		margin-bottom: 20px;
	}

	&-item {
		background-color: $color-light-grey;
		border-radius: 4px;
		font-size: 13px;
		padding: 20px;

		& + & {
			margin-top: 20px;
		}

		&.m-active {
			background-color: $color-bg-ma;
		}
	}

	&-product {
		display: flex;
		margin-bottom: 20px;
	}

	&-picture {
		border-radius: 2px;
		margin-right: 20px;
	}

	&-subtitle {
		display: flex;
		font-weight: 700;
		margin-bottom: 5px;

		@include media(lg-up) {
			align-items: center;
		}

		svg {
			margin: 3px 9px 0 0;
			min-width: 16px;

			@include media(lg-up) {
				margin-top: 0;
			}
		}
	}

	&-price {
		align-items: center;
		display: flex;

		.b-price {
			margin-right: 5px;
		}
	}

	&-date_row {
		margin: 3px 0;
	}

	&-date {
		font-weight: 600;
	}

	&-link {
		display: block;
		font-weight: 700;
		margin-top: 5px;
	}

	li {
		padding-left: 14px;
		position: relative;

		&::before {
			background-color: $color-primary;
			border-radius: 100%;
			content: '';
			display: block;
			height: 3px;
			left: 4px;
			position: absolute;
			top: 50%;
			width: 3px;
		}
	}
}
