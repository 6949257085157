.b-account_buy_again {
	margin-bottom: 50px;

	&-title {
		@include g-heading_4;

		color: $color-title;
		margin-bottom: 20px;

		@include media(md-down) {
			text-align: center;
		}
	}

	&-items_count {
		font-size: 13px;
		margin-bottom: 20px;

		@include media(lg-up) {
			text-align: right;
		}
	}

	&-grid {
		display: grid;
		grid-gap: 20px 9px;
		grid-template-columns: repeat(2, 1fr);

		@include media(lg-up) {
			grid-gap: 20px;
			grid-template-columns: repeat(3, 1fr);
		}
	}

	&-actions {
		grid-column: 1/-1;
	}
}
