.b-social {
	align-items: center;
	display: flex;

	&-title {
		font-size: 16px;
		font-weight: 500;
		margin-right: rh(3);

		@include media(sm) {
			display: none;
		}
	}

	&-items {
		display: flex;
	}

	&-link {
		@include g-button_iconed;

		color: $color-text-grey;
	}
}
