.l-downloads_grid {
	display: grid;
	grid-gap: 20px 9px;
	grid-template-columns: repeat(2, 1fr);

	@include media(lg-up) {
		grid-gap: 20px;
		grid-template-columns: repeat(3, 1fr);
	}

	&-actions {
		grid-column: 1/-1;
	}
}
