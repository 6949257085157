.b-reset_password {
	position: relative;

	&-btn {
		@include g-link;

		color: $color-action-obj;
		font-size: 12px;
		font-weight: 600;
		line-height: 15px;
		position: absolute;
		right: 0;
		top: 2px;
		transition: color $motion-fast;

		&::after { // to increase the clickable area
			bottom: -6px;
			content: '';
			left: 0;
			position: absolute;
			right: 0;
			top: -6px;
		}

		.b-checkout_login-section & {
			font-size: 11px;
			font-weight: 600;
			line-height: 14px;
		}
	}

	&-submit {
		font-weight: 400;
		line-height: 13px;
	}
}
