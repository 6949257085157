.b-status_bar {
	display: flex;
	font-size: 13px;
	justify-content: space-between;
	overflow: hidden;

	&-item {
		padding: 30px 15px 0;
		position: relative;
		text-align: center;

		&::before {
			background: $color-statusbar-line;
			content: '';
			height: 2px;
			left: 50%;
			position: absolute;
			top: 7px;
			width: 400%;
		}

		&::after {
			background: $color-bg;
			border: 2px solid $color-statusbar-line;
			border-radius: 100%;
			content: '';
			height: 16px;
			left: 50%;
			margin-left: -8px;
			position: absolute;
			top: 0;
			width: 16px;
		}

		&:first-child {
			padding-left: 0;
			text-align: left;

			&::before,
			&::after {
				left: 0;
				margin-left: 0;
			}
		}

		&:last-child {
			padding-right: 0;
			text-align: right;

			&::before {
				content: none;
			}

			&::after {
				left: auto;
				right: 0;
			}
		}

		&.m-completed {
			&::before,
			&::after {
				background-color: $color-statusbar-line-active;
				border-color: $color-statusbar-line-active;
			}
		}

		&.m-active {
			&::after {
				background-color: $color-statusbar-line-active;
				border-color: $color-statusbar-line-active;
			}
		}
	}
}
