.b-cards_grid {
	$root: &;

	max-width: 485px;

	&-item {
		border: $global-line solid $color-divider;
		border-radius: $global-radius;
		box-shadow: $depth-1;
		color: $color-primary;
		font-size: 15px;
		line-height: 1.5;
		margin-bottom: 20px;
		min-height: 200px;
		padding: 20px 20px 15px;

		&.m-payment_card.m-default {
			border: 2px solid $color-info;
		}

		&.m-invalid {
			border-color: $color-error;
		}

		&.m-payment_card #{$root}-info {
			@include media(md-down) {
				display: flex;
				flex-direction: column;

				.b-payment_icon {
					margin-bottom: 16px;
				}
			}
		}
	}

	&-item_new {
		font-size: 15px;
		margin: 35px 0;
	}

	&-add_new {
		@include g-link;

		color: $color-title;
		display: block;
		font-size: 15px;
		font-weight: 600;
		margin: 35px 0;
		text-align: center;

		&.m-empty {
			text-align: left;
		}
	}

	&-header {
		align-items: baseline;
		display: flex;
		justify-content: flex-end;
	}

	&-header_left {
		margin-right: auto;
	}

	&-header_right {
		@include media(md-down) {
			.b-cards_grid-link {
				display: block;
				margin-bottom: 20px;
				text-align: right;
			}
		}
	}

	&-title {
		font-weight: 600;
		margin-bottom: 16px;

		&.m-expired {
			color: $color-error;
		}
	}

	&-subtitle {
		font-weight: 600;
		margin-bottom: 16px;
	}

	&-shipping {
		margin-top: 20px;
	}

	&-link {
		@include g-link;

		color: $color-title;
		font-size: 13px;
		font-weight: 600;

		&.m-default {
			font-size: 15px;
		}

		&.m-set_default {
			font-size: 15px;
			margin-bottom: 16px;
		}
	}

	&-delete {
		@include media(lg-up) {
			margin-left: 20px;
		}
	}

	&.m-address_card .b-address-title {
		display: none;
	}

	.b-address {
		margin-bottom: 5px;
	}
}
