/*md

# Input password

Designed with possibility to `show/hide` masked text into input fields

```html_example
<div class="b-input_password" data-widget="inputPassword">
	<input data-ref="field" id="" type="password" class="b-input m-valid" aria-describedby="" placeholder="" name="" required="" value="" maxlength="255" aria-required="true" minlength="8" data-event-input="onPasswordInput" data-tau="" data-event-blur="validate">
	<button class="b-input_password-toggle_visibility" data-ref="showButton" data-event-click="toggleMask" data-button-text-show="Show" data-button-text-hide="Hide" aria-pressed="false" title="Toggle password field visibility" type="button">
		Show
	</button>
</div>
```

```html_example
<div class="b-input_password" data-widget="inputPassword">
	<input data-ref="field" id="" type="password" class="b-input m-valid" aria-describedby="" placeholder="" name="" required="" value="" maxlength="255" aria-required="true" minlength="8" data-event-input="onPasswordInput" data-tau="" data-event-blur="validate">
	<button class="b-input_password-toggle_visibility" data-ref="showButton" data-event-click="toggleMask" data-button-text-show="Show" data-button-text-hide="Hide" aria-pressed="false" title="Toggle password field visibility" type="button" hidden="hidden">
		Show
	</button>
</div>
```

## Error State

```html_example
<div class="b-input_password" data-widget="inputPassword">
	<input data-ref="field" id="" type="password" class="b-input m-invalid" aria-describedby="" placeholder="" name="" required="" value="" maxlength="255" aria-required="true" minlength="8" data-event-input="onPasswordInput" data-tau="" data-event-blur="validate">
	<button class="b-input_password-toggle_visibility" data-ref="showButton" data-event-click="toggleMask" data-button-text-show="Show" data-button-text-hide="Hide" aria-pressed="false" title="Toggle password field visibility" type="button" hidden="hidden">
		Show
	</button>
</div>
```
*/

.b-input_password {
	align-items: center;
	display: flex;
	position: relative;

	&-toggle_visibility {
		@include g-link;

		color: $color-action-obj;
		cursor: pointer;
		font-size: 16px;
		font-weight: 500;
		position: absolute;
		right: rh(4);
		transition: color $motion-fast;
	}
}
