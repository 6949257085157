.b-summary_address {
	font-size: 15px;
	line-height: 1.5;
	word-break: break-word;

	&-default {
		color: $color-text-dimmed;
		font-weight: 500;
		margin-top: rh(4);
	}
}
