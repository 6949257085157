.b-summary_table {
	color: $color-primary;
	font-size: 15px;
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	width: 100%;

	&-name {
		&.m-tooltip {
			display: flex;
		}

		&.m-free_cc {
			align-items: center;
			color: $color-dark2-grey;
			display: flex;
			font-size: 12px;
			padding-top: 0;
		}
	}

	&-value {
		font-weight: 500;
		text-align: right;
		vertical-align: top;
		white-space: nowrap;

		&.m-free_shipping {
			text-transform: uppercase;
		}

		&.m-top_align {
			vertical-align: top;
		}
	}

	&-name,
	&-value {
		font-weight: inherit;
		padding-top: 6px;
	}

	&-item {
		&.m-total {
			font-size: 15px;
			font-weight: 600;

			.b-summary_table-name,
			.b-summary_table-value {
				padding-top: 20px;
			}
		}
	}

	&-item.m-gift_card &-value,
	&-item.m-gift_card &-name {
		padding-top: 20px;
	}

	.m-expanded &-item.m-total {
		.b-summary_table-name,
		.b-summary_table-value {
			padding-top: 12px;
		}
	}

	&-tax {
		font-size: 13px;
		font-weight: 400;
		margin-top: 5px;
	}
}
