.b-order_details {
	color: $color-primary;

	&.m-order {
		margin-bottom: 40px;
	}

	&.m-guest {
		display: block;
		margin-top: 30px;

		@include media(lg-up) {
			display: flex;
			justify-content: space-between;
		}
	}

	&-top {
		text-align: center;

		@include media(md-up) {
			align-items: baseline;
			display: flex;
			justify-content: space-between;
		}
	}

	&-caption {
		@include g-heading_4;

		color: $color-title;
	}

	&-back_link {
		@include g-link;

		color: $color-title;
		display: inline-block;
		font-size: 15px;
		font-weight: 600;

		@include media(sm) {
			margin-top: 10px;
		}
	}

	&-lines {
		font-size: 15px;
		line-height: 1.5;
		margin-top: 36px;
	}

	&-line {
		margin-top: 5px;
	}

	&-line_title {
		font-weight: 600;
	}

	&-status_bar {
		margin-top: 30px;
		max-width: 553px;

		@include media(lg-up) {
			margin-top: 25px;
		}
	}

	&-main {
		flex: 1;
	}

	&-products {
		margin-top: 20px;
	}

	&-aside {
		margin-top: 36px;

		&.m-account {
			@include media(lg-up) {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
			}
		}

		&.m-guest {
			@include media(lg-up) {
				flex-basis: grid-span(4, 'lg');
				margin-top: 0;
				padding-left: 20px;
			}
		}

		&.m-checkout {
			margin-top: 0;
		}
	}

	&-aside_item {
		background-color: $color-info-obj;
		border: $global-line solid $color-divider;
		border-radius: $global-radius;
		box-shadow: $depth-1;
		padding: 20px;

		@include media(lg-up) {
			flex-basis: calc(50% - 10px);
		}

		&.m-full {
			@include media(lg-up) {
				flex-basis: 100%;
			}
		}

		& + & {
			margin-top: 24px;
		}
	}

	&-title {
		@include g-heading_6;

		color: $color-title;
		margin-bottom: 20px;
	}

	&-subtitle {
		font-weight: 600;
		margin-bottom: rh(3);
	}

	&-header {
		font-size: 15px;
		margin-bottom: 32px;
	}

	&-header_title {
		font-weight: 600;
	}
}
