.l-account {
	@include g-section_holder;

	padding-bottom: 70px;
	padding-top: 35px;

	&-info {
		margin: 20px 0;
		text-align: center;
	}

	&-message {
		margin-bottom: 30px;
		width: 100%;
	}

	&-nav {
		overflow: hidden;
		width: 100%;

		@include media(lg-up) {
			flex-basis: grid-span(4, 'lg');
			padding-right: 45px;
		}
	}

	&-main {
		flex-basis: 100%;
		width: 100%;

		@include media(lg-up) {
			flex-basis: grid-span(8, 'lg');
			max-width: grid-span(8, 'lg');
		}
	}

	&.m-account {
		padding-top: 0;
	}

	&.m-px-0 {
		padding-left: 0;
		padding-right: 0;
	}

	&.m-poq {
		padding-bottom: 30px;
		padding-top: 30px;
	}

	.b-latest_order {
		border-top: $global-line solid $color-divider;
		padding-top: 30px;

		.b-card {
			padding-left: 0;
			padding-right: 0;

			&-body,
			&-list {
				color: $color-charcoal;

				&_link {
					color: $color-charcoal;
				}
			}

			&-title {
				color: $color-charcoal;
			}

			.b-address-title {
				color: $color-charcoal;
			}
		}
	}
}
