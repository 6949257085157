/*md

# Form

Form component designed to create an easy and versatile form layout system.

Forms are built with a combination of standard form elements with a `.b-form_section` wrapper to hold labels, errors and caption for each field.\

Each line can hold from one to three form elements, it required only modifier to control that. Also it possible to use any form element inside lines

```html_example
<form class="b-form" autocomplete="on" novalidate="" data-widget="shippingForm" data-event-submit.prevent="handleSubmit" data-widget-event-updateshippingform="updateSummaryEv" data-address-selector="shippingAddressSelector" data-address-fields="dwfrm_shipping_shippingAddress_addressFields_firstName,dwfrm_shipping_shippingAddress_addressFields_lastName,dwfrm_shipping_shippingAddress_addressFields_address1,dwfrm_shipping_shippingAddress_addressFields_address2,dwfrm_shipping_shippingAddress_addressFields_country,dwfrm_shipping_shippingAddress_addressFields_states_stateCode,dwfrm_shipping_shippingAddress_addressFields_city,dwfrm_shipping_shippingAddress_addressFields_postalCode,dwfrm_shipping_shippingAddress_addressFields_phone" data-widget-event-submit="shippingSubmitted" data-select-shipping-method-url="https://zzrk-007.sandbox.us01.dx.commercecloud.salesforce.com/on/demandware.store/Sites-Boilerplate-NA-Site/en_US/CheckoutShippingServices-SelectShippingMethod" data-update-shipping-list-url="https://zzrk-007.sandbox.us01.dx.commercecloud.salesforce.com/on/demandware.store/Sites-Boilerplate-NA-Site/en_US/CheckoutShippingServices-UpdateShippingMethodsList" data-create-shipment-url="https://zzrk-007.sandbox.us01.dx.commercecloud.salesforce.com/on/demandware.store/Sites-Boilerplate-NA-Site/en_US/CheckoutAddressServices-CreateNewAddress" action="/on/demandware.store/Sites-Boilerplate-NA-Site/en_US/CheckoutShippingServices-SubmitShipping" data-address-mode="edit" data-form-definition-url="/on/demandware.store/Sites-Boilerplate-NA-Site/en_US/Forms-FormDefinition?form=shipping" name="dwfrm_shipping" id="dwfrm_shipping" data-address-autocomplete-enabled="false" data-initialized="1" aria-busy="false">

	<div class="b-form-message" role="alert" data-ref="errorMessageLabel">Form error message here</div>

	<fieldset class="b-form-set" data-ref="addressForm">
		<legend class="b-form-set_label">
			Shipping address (form-set)
		</legend>

		<div class="b-form_section">
			<label class="b-form_section-label">
				First Name (form_section)
			</label>
			<input class="b-input" type="text" placeholder="First Name">
		</div>

		<div class="b-form-line m-equal">
			<div class="b-form_section">
				<label class="b-form_section-label">
					First Name (form-line equal)
				</label>
				<input class="b-input" type="text" placeholder="First Name">
			</div>

			<div class="b-form_section">
				<label class="b-form_section-label">
					Last Name (form-line)
				</label>
				<input class="b-input" type="text" placeholder="Last Name">
			</div>
		</div>

		<div class="b-form-line m-fixed_width">
			<div class="b-form_section">
				<label class="b-form_section-label">
					First Name (form-line fixed-width)
				</label>
				<input class="b-input" type="text" placeholder="First Name">
			</div>

			<div class="b-form_section">
				<label class="b-form_section-label">
					Last Name
				</label>
				<input class="b-input" type="text" placeholder="Last Name">
			</div>

			<div class="b-form_section">
				<label class="b-form_section-label">
					Phone Number
				</label>
				<input class="b-input" type="text" placeholder="Phone Number">
			</div>
		</div>
	</fieldset>
</form>
```
*/

.b-form {
	&-title {
		@include g-heading_3;

		margin-bottom: 20px;
	}

	&-caption {
		font-size: 15px;
		line-height: 1.5;
	}

	&-description {
		font-size: 13px;
		margin-top: 15px;
	}

	&-message {
		background: $color-error-obj;
		color: $color-error;
		font-weight: 300;
		margin-bottom: 20px;
		padding: 8px 16px;
		text-align: center;

		.b-checkout_step-content & {
			background: $color-error;
			border-radius: $global-radius;
			color: $color-text-white;
			padding: 7px 9px 7px 32px;
			position: relative;
			text-align: left;

			&::before {
				background: url('./images/icons/info.svg') no-repeat;
				content: '';
				height: 20px;
				left: 9px;
				position: absolute;
				top: 7px;
				width: 20px;
			}
		}
	}

	&-error_feedback {
		color: $color-error;
		font-size: 12px;
		font-weight: 500;
		margin-top: rh(5);
	}

	&-set {
		border: none;
		display: block;
		margin: rh(4 0);
		min-width: 0;
		padding: 0;
		position: relative;

		&[hidden] {
			display: none;
		}
	}

	&-set_label {
		@include g-heading_6;

		color: $color-primary;
		display: block;
		font-weight: 600;
		margin-bottom: 32px;
		max-width: 100%;
		padding: 0;
		white-space: normal;
		width: 100%;

		@include media(md-down) {
			margin-bottom: 24px;
		}

		&.m-wai {
			@include hide(visually);
		}
	}

	&-line {
		display: flex;
		margin-top: 20px;
		width: 100%;

		@include media(lg-up) {
			margin-top: 16px;
		}

		&.m-actions {
			margin-top: 35px;
		}

		&[hidden] {
			@include hide(visually);
		}

		> .b-form_section {
			width: 100%;
		}

		&.m-equal {
			justify-content: space-between;

			.b-form_section {
				width: calc(50% - #{30px});

				@include media(sm) {
					width: 72%;
				}
			}
		}

		&.m-fixed_width {
			.b-form_section {
				width: 215px;
			}
		}

		&.m-mobile_column {
			margin-top: 36px;

			.b-account_details & {
				justify-content: start;
			}

			.b-form.m-account & {
				justify-content: space-between;
			}
		}

		&.m-card_expiration {
			flex-wrap: nowrap;
		}

		.b-billing_form &,
		.b-form.m-checkout_shipping &,
		.b-payment_accordion-item.m-paypal & {
			margin-top: 0;
		}
	}

	&-line_divider {
		align-items: center;
		display: flex;
		font-size: 16px;
		font-weight: 300;
		height: $size-input-height;
		margin: rh(0 4);
	}

	&-btn_save {
		margin-right: 40px;
		min-width: 217px;
	}

	&-btn_cancel {
		color: $color-action-obj;
		font-size: 15px;
		font-weight: 600;
		line-height: 22px;
		margin: auto 0;
		text-decoration: underline;

		@include media(md-down) {
			padding-right: 25px;
		}

		&.m-link {
			color: $color-action-obj;
		}
	}

	&-section {
		.b-form_section-label {
			font-weight: 700;
		}

		.b-input {
			border-color: $color-border-active;
		}
	}

	&-link {
		color: $color-primary;
		display: block;
		font-size: 13px;
		margin-bottom: 35px;
	}

	&.m-account {
		@include media(md-up) {
			flex-grow: 0;
			max-width: 500px;
		}

		@include media(md-down) {
			margin: 0 auto 30px;
		}
	}

	&.m-account-address {
		margin-bottom: 50px;

		@include media(md-up) {
			max-width: 396px;
		}
	}

	&.m-change_password {
		margin-bottom: 30px;

		@include media(lg-up) {
			max-width: 313px;
		}
	}

	&.m-trackorder {
		margin: auto;
		max-width: 271px;
	}

	&.m-registration {
		.b-button {
			width: 271px;
		}

		.b-form_section.m-button_row,
		.b-registration-date .b-form_section {
			width: 100%;
		}
	}

	&.m-busy {
		cursor: wait;
		opacity: 0.6;
	}
}
