.b-user_greeting {
	color: $color-title;
	display: flex;
	margin-bottom: 20px;

	&-icon {
		@include g-icon_gradient;

		background-color: $color-info-obj;
		background-image: none;
		margin-right: 20px;
	}

	&-content {
		overflow: hidden;
	}

	&-message {
		@include g-heading_5;

		margin-bottom: 5px;
		word-break: break-word;
	}

	&-signout {
		@include g-link;

		font-size: 15px;
		font-weight: 600;
		transition: color $motion-fast;
	}
}
