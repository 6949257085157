.b-account {
	color: $color-primary;
	display: flex;
	flex-wrap: wrap;
	margin: auto;

	&.m-login {
		@include media (md-up) {
			max-width: 350px;
		}

		.b-tab_panel {
			padding: 0 15px;

			@include media (lg-up) {
				padding: 0 35px;
			}
		}
	}

	&-title {
		@include g-heading_4;

		color: $color-title;
		margin-bottom: 30px;
		text-align: center;
	}

	&.m-account &-title {
		text-align: left;
	}

	&-empty_state {
		margin: 15px 0 40px;
	}

	&-register {
		margin: 0 -5px;

		@include media (md-up) {
			margin: 0 -55px;
		}

		.b-account_benefits {
			color: inherit;
		}
	}

	&_overview {
		border-bottom: 1px solid $color-mid-grey;
		display: grid;
		gap: 8px;
		grid-template-columns: 1fr;
		margin-bottom: 32px;
		padding-bottom: 32px;

		@include media(md-up) {
			gap: 24px;
			grid-template-columns: 1fr 1fr;
		}
	}

	&_panel {
		background-color: $color-white;
		border: 2px solid #96D6D4;
		border-radius: 4px;
		color: $color-charcoal;
		display: flex;
		font-weight: 600;
		justify-content: space-between;
		line-height: 1.25;
		min-height: 80px;

		@include media(md-up) {
			box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
		}

		&_solid-bg {
			background-color: #96D6D4;
			border-bottom: transparent;
			border-top: transparent;
		}

		&-title {
			font-size: 20px;
			margin-bottom: 5px;
		}

		&-body {
			font-size: 14px;
			text-decoration: underline;
		}

		&-link {
			align-items: center;
			color: $color-charcoal;
			display: flex;
			flex: 1;
			gap: 8px;
			justify-content: space-between;
			padding: 12px 16px;
			position: relative;

			&:hover {
				text-decoration: none;
			}

			svg {
				height: 100%;
				position: absolute;
				right: 0;
				top: 0;

				@include media(md-up) {
					right: 16px;
				}
			}
		}

		&-block {
			z-index: 1;
		}

		&-odd {
			order: 2;

			@include media(md-up) {
				order: initial;
			}

			.b-account_panel-link {
				svg {
					right: 16px;
				}
			}
		}
	}

	&_dashboard {
		display: grid;
		gap: 8px;
		grid-template-columns: 1fr;
		margin-bottom: 32px;

		@include media(md-up) {
			gap: 24px;
			grid-template-columns: 1fr 1fr 1fr;
		}
	}

	&_card {
		background-color: $color-light-grey;
		border: 1px solid $color-mid-grey;
		border-radius: 4px;
		box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
		color: $color-burgundy;
		line-height: 1.25;

		&-title {
			font-size: 17px;
			font-weight: 500;
			margin-bottom: 5px;
		}

		&-body {
			font-size: 13px;
			font-weight: 400;
		}

		&-link {
			align-items: center;
			display: flex;
			gap: 8px;
			padding: 13px 16px;

			&:hover {
				text-decoration: underline;

				.b-account_card-title {
					font-weight: 600;
				}
			}
		}
	}
}
