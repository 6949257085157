.localities-input-wrapper,
.localities-input-container {
	width: 100%;
}

.localities-input {
	&.b-input {
		border-radius: $input-radius;
		font-size: 13px;
		height: $size-input-height;
		padding: 0 10px;

		&:focus {
			@include media(md-down) {
				font-size: 16px;
			}
		}
	}
}

.localities-empty-button {
	display: none; // hide Woosmap clear button as we use own custom one
}

.localities-item {
	box-sizing: content-box;
	cursor: pointer;
}

.localities-item-selected .localities-icon,
.localities-item:hover .localities-icon {
	background-color: $color-action-obj;
}
