.b-account_banner {
	color: $color-bg;
	font-size: 15px;
	line-height: 1.5;
	margin: 0 auto 20px;
	width: 100%;

	@include media(lg-up) {
		margin: 0 auto 30px;
	}

	&-inner {
		background-color: $color-bg-account-banner;
		border-radius: $global-radius;
		padding: 10px;
		text-align: center;
	}

	&-link {
		@include g-link;

		display: inline-block;
		font-weight: 600;
		transition: color $motion-fast;
	}
}
