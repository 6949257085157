.b-account_details {
	margin-bottom: 30px;
	max-width: 398px;

	@include media(md) {
		margin: 0 auto 30px;
	}

	&-email {
		font-weight: 600;
		word-spacing: 5px;
	}

	&-btn_save {
		margin-right: 40px;
		min-width: 244px;

		@include media(md-down) {
			min-width: 226px;
		}
	}
}
